<template>
  <div class="pageCenter">
    <el-backtop></el-backtop>
    <img src="../../assets/image/新闻资讯/资讯中部广告.png" alt="" />
    <div class="info-content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/newsConsult' }"
          >返回 |
        </el-breadcrumb-item>
      </el-breadcrumb>
      <h1>企业资讯</h1>
      <h2>宜昌交通局公共交通智能监控平台顺利落地</h2>
      <p class="intro_t">
        宜昌公交运行和监测数据中心的职责是立足于公交行业，用大数据的方法对数据做挖掘以及分析，通过我们挖掘出的
        数据规律为公共交通行业的决策提供数据支持。我们的目标是打造交通行业数字化运营决策产品，提供给公交企业管理人
        员使用，解决数据运营、科学管理、线网优化等交通行业问题。目前该项目已经完成了线路、站点、乘客、车辆以及IC
        卡等多个主题的研发工作。在离线计算模块，我们采用数据仓库的原理，运用各种数据分析手段，挖掘客户所需要的离线
        指标，为公共交通行业的发展决策以及相关业务的拓展提供数据支持。
      </p>
      <div class="content-center">
        <img src="../../assets/image/新闻资讯/图层78.png" alt="" />
      </div>
      <p class="intro">
        主要规划<br>
        （1）对线网、线路、车辆、驾驶员、乘客建立数据分析模型，建立数仓；<br>
        （2）针对大屏实体，创建7*3的布局UI设计，整体设计分为三块，实时数据分析模型，历史数据分析模型，线网、车辆
        与乘客监测模型；<br>
        （3）建立并优化分析模型，针对客运量走势、上座率、运力、线路OD、从业人员、支付、载客、乘客画像、客流排名等
        建立分析维度；<br>
        （4）结合宜昌公交数据，建立线网、车辆、乘客的监测模型，实现可视化交互；<br>
        （5）完成系统的上线部署；<br>
        （6）对现有业务升级优化、指标数据计算的升级、针对可视化效果的优化升级。
      </p>
      <p class="intro_t">随着进入6月，项目已经成功交付，并稳定试运营1个月时间，我司也配合交通局对数据监控业务进行了多次的演示
汇报,并获得了宜昌各市级领导的肯定。</p>
      <!-- <div class="content-center card">
        <img src="../../assets/image/关于我们/锐明.png" alt="" />
        <div class="title">卡片标题</div>
        <div class="content">
          卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容卡片内容
        </div>
      </div>
      <div class="content-center function-img">
        <div class="function-box">
          <img src="../../assets/image/首页/邮箱.png" alt="" />
          <div>心率</div>
        </div>
        <div class="function-box">
          <img src="../../assets/image/首页/邮箱.png" alt="" />
          <div>心率</div>
        </div>
        <div class="function-box">
          <img src="../../assets/image/首页/邮箱.png" alt="" />
          <div>心率</div>
        </div>
        <div class="function-box">
          <img src="../../assets/image/首页/邮箱.png" alt="" />
          <div>心率</div>
        </div>
        <div class="function-box">
          <img src="../../assets/image/首页/邮箱.png" alt="" />
          <div>心率</div>
        </div>
      </div>
      <p class="intro">
        武汉轩迪是一家创新型的科技公司，本公司依托物联网、大数据、人工智能等数字
        化技术，遵循”智慧交通“”便捷公交“”绿色公交“的行业发展理念，对接公共交通各
        类资源，打通公共交通营运、安全能耗、线上线下融合、支付等相关数据节点，构建公
        共交通大数据系统融合决策平台，为公共交通行业提供数字化决策服务，助力公共交通
        行业达到降本增效、智慧运营的目的.
      </p> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.push('/newsConsult')
    }
  }
}
</script>
<style scoped lang="less">
.el-breadcrumb {
  display: inline-block;
}
h1 {
  display: inline-block;
  font-size: 40px;
  color: #333;
  margin: 0 0 50px 30px;
  border-bottom: 2px solid #3468d2;
}
.el-page-header {
  width: 200px;
  margin: 50px 0;
}
h2 {
  margin-top: 0 64px;
  font-size: 42px;
  color: #333;
}
img {
  width: 100%;
}
.info-content {
  margin: 82px 160px 60px;
}
.content-center {
  text-align: center;
  margin: 20px 0;
}
.el-page-header__content::after {
  content: '';
  display: block;
  width: 4%;
  height: 2px;
  margin-top: 8px;
  background-color: red;
}
.intro ,.intro_t{
  margin-top: 20px;
  font-size: 30px;
  line-height: 72px;
  color: #333;
}
.intro_t{
  text-indent: 2em;
}
.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 18px;
  margin: 20px 14%;
  img {
    object-fit: cover;
    grid-row: 1 / 3;
  }
  .title {
    font-size: 24px;
    margin-top: 10px;
  }
}
.function-img {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 20px 14%;
  .function-box {
    margin: 10px;
    img {
      width: 60px;
    }
  }
}
</style>
